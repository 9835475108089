import '../../scss/info-page.scss';
import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicyPage = () => {
  return (
    <div className="second-background">
      <Helmet>
        <title>Privacy Policy - Steam WebAPI Extension</title>
        <meta name="description" content="This privacy policy explains how the Steam WebAPI Token Extension manages data." />
      </Helmet>
      <h1 className="page-title">Privacy Policy</h1>
      <article className="description page-content">
        <p>This privacy policy explains how the Steam WebAPI Token Extension ("we," "our," or "us") manages data. The extension is
          specifically designed to display a token to the user and provides a convenient option to copy that token directly from
          the extension. This document clarifies the processes and policies regarding data handling to ensure transparency for our
          users.</p>

        <h2>Data Collection and Use</h2>
        <h3>User Data:</h3>
        <p>We want to reassure users that the extension does not collect, store, or process any personal or sensitive data. It
          operates exclusively within the user's currently active browser tab and is entirely isolated from tracking or monitoring
          user activities or gathering personal information in any way.</p>

        <h3>Website Data:</h3>
        <p>To fulfill its primary function, the extension retrieves specific data from <a href="https://steamcommunity.com"
                                                                                          target="_blank">steamcommunity.com</a>,
          which is necessary to connect to the platform and display the token. This data is used solely for the purpose of
          providing the token to the user, without storing or processing additional information.</p>

        <h2>Data Sharing and Disclosure</h2>
        <p>Since our extension neither collects nor stores any personal or sensitive information, there is no data to be shared or
          disclosed with third parties. We remain committed to maintaining user privacy by ensuring that no data transmission
          occurs in relation to personal information.</p>

        <h2>Data Security</h2>
        <p>While security is a key consideration in the design of this extension, the absence of any data collection or storage
          minimizes the risks associated with data breaches or leaks. By not processing user data, we eliminate potential
          vulnerabilities, making the extension a secure tool for its intended purpose.</p>

        <h2>Updates to This Privacy Policy</h2>
        <p>We reserve the right to revise this privacy policy from time to time, especially in response to evolving legal
          requirements or changes in our practices. Users are encouraged to periodically review this document to stay informed
          about any modifications.</p>

        <h2>Contact Information</h2>
        <div className="contact-info">
          <p>If you have any questions, concerns, or requests for clarification regarding this privacy policy, feel free to reach
            out to us at <a href="mailto:dpo@ancient.gg">dpo@ancient.gg</a>.</p>
        </div>
      </article>
      {/* Add more content as needed */}
    </div>
  );
};

export default PrivacyPolicyPage;
